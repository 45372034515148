<template>
  <div id="content">
    <div class="swiper">
      <p class="title">工程介绍</p>

      网络游戏未成年人家长监护工程”是一项由零柒互娱等多家网络游戏企业共同发起并参与实施，由中华人民共和国文化部指导， 旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，和谐家庭关系的社会性公益行动。它提供了 一种切实可行的方法，一种家长实施监控的管道，使家长纠正未成年子女沉迷游戏的行为成为可能。该项社会公益行动充分反映了 中国网络游戏行业高度的社会责任感，对未成年玩家合法权益的关注及对用实际行动营造和谐社会的愿望。
      <p style="font-size:20px;font-weight:bold">《未成年人健康参与网络游戏提示》</p>
      <p>
        &emsp; &emsp;随着网络在青少年中的普及，未成年人接触网络游戏已经成为普遍现象。为保护未成年人健康参与游戏，在政府进一步加强行业管 理的前提下，家长也应当加强监护引导。为此，我们为未成年人参与网络游戏提供以下意见：
      </p>
      <p>

        &emsp; &emsp;一、主动控制游戏时间。游戏只是学习、生活的调剂，要积极参与线下的各类活动，并让父母了解自己在网络游戏中的行为和体验
      </p>
      <p>
        &emsp; &emsp;二、不参与可能耗费较多时间的游戏设置。不玩大型角色扮演类游戏，不玩有PK类设置的游戏。在校学生每周玩游戏不超过2小时,每月在游戏中的花费不超过10元。

      </p>
      <p>
        &emsp; &emsp;三、不要将游戏当作精神寄托。尤其在现实生活中遇到压力和挫折时，应多与家人朋友交流倾诉，不要只依靠游戏来缓解压力。

      </p>
      <p>
        &emsp; &emsp;四、养成积极健康的游戏心态。克服攀比、炫耀、仇恨和报复等心理，避免形成欺凌弱小、抢劫他人等不良网络行为习惯。

      </p>
      <p>

        &emsp; &emsp;五、注意保护个人信息。包括个人家庭、朋友身份信息，家庭、学校、单位地址，电话号码等，防范网络陷阱和网络犯罪。
      </p>
      <p class="title">服务申请</p>
      <p style="font-size:20px;font-weight:bold">监护人提出申请</p>
      <p>

        在监护人发现被监护人有沉溺《成都惊叹号科技有限公司》网络游戏的情况下，监护人可向《成都惊叹号科技有限公司》申请发起未成年人家长监护机制。
      </p>
      <p>
        监护人需亲自通过邮寄方式向我司提供有效材料，向《成都惊叹号科技有限公司》提出未成年人账户监控的申请。在收到邮件后，我司即开始启动监护机制审核流程，首先进入疑似账号身份确认期。

      </p>

      <p style="font-size:20px;font-weight:bold">疑似账号身份确认期（15个自然日）</p>
      <p>
        在判断申请材料完整的情况下，我司将通过官方邮箱联系疑似帐号归属者，告知其在15个自然日内将按照监护人需求对其账号进行相关操作，并要求疑似账号归属者提供身份材料以便我司判定其与监护人监护关系；
      </p>
      <p>

        若疑似账号归属者在15个自然日内不能提供有效身份证明或逾期提供，则默认为疑似账号归属者与被监护人身份相符。我司即按照监护人申请要求，对其游戏账号纳入防沉迷系统；
      </p>
      <p>

        若疑似账号归属者在15个自然日内提供的身份证明与被监护人相符，我司即按照监护人申请要求，对其游戏账号纳入防沉迷系统；
      </p>
      <p>

        若疑似账号归属者在15个自然日内提供的身份证明与被监护人不符，我司则无法判定其与被监护人的身份关系。在此情况下，为保障用户游戏账号安全，我司将通知监护人通过公安机关出具账号找回协查证明，由我司协助被监护人找回游戏账号后再进行后续操作。
      </p>
      <p style="font-size:20px;font-weight:bold"> 监护服务申请书</p>
      <p>

        表单1：《成都惊叹号科技有限公司游戏未成年人用户家长监控服务申请书》
      </p>
      <p>

        温馨提醒，您在邮寄申请书时，要记得一起提供如下资料：
      </p>
      <p>

        附件1：申请人的身份证（复印件）
      </p>
      <p>

        附件2：被申请人的身份证（复印件）
      </p>
      <p>
        附件3：申请人与被申请人的监护法律关系证明文件（户口簿或者其他合法有效证明文件）

      </p>

      <p class="title">常见问题解答</p>

      <p> Q1：“网络游戏未成年人家长监护机制工程”是什么？</p>

      <p> “网络游戏未成年人家长监护工程”是一项由零柒互娱等多家网络游戏企业共同发起并参与实施，由中华人民共和国文化部指导，旨在加强家长对未成年人参与网络游戏的监护，引导未成年人健康、绿色参与网络游戏，和谐家庭关系的社会性公益行动。它提供了一种切实可行的方法，一种家长实施监控的管道，使家长纠正部分未成年子女沉迷游戏的行为成为可能。该项社会公益行动充分反映了中国网络游戏行业高度的社会责任感，对未成年玩家合法权益的关注以及对用实际行动营造和谐社会的愿望。
      </p>
      <p>
        Q2：什么情况下需要申请该项服务呢？
      </p>

      <p>
        在监护人发现被监护人有沉溺《成都惊叹号科技有限公司》网络游戏的情况下，监护人可向《成都惊叹号科技有限公司》公司申请发起未成年人家长监护机制。
      </p>

      <p>
        Q3：申请该项服务时需要提交哪些资料呢？如何提交呢？

      </p>
      <p>
        申请人需通过邮寄方式向我司提交《成都惊叹号科技有限公司游戏未成年人用户家长监控服务申请书》及其中所提及需提供的附件，包含：
        附件1：申请人的身份证（复印件）
        附件2：被申请人的身份证（复印件）
        附件3：申请人与被申请人的监护法律关系证明文件（户口簿或者其他合法有效证明文件）
        您在填写《成都惊叹号科技有限公司游戏未成年人用户家长监控服务申请书》时，请提供尽可能详尽、真实的玩家资料，以便帐号归属的判定。
      </p>
      <p>
        Q4：相关资料寄往哪里呢？
      </p>

      <p>
        请将相关资料邮寄至：（请在信封表面写上“家长监控服务申请书”的字样）
        收件人：成都惊叹号科技有限公司 客服部游戏组
        地址：成都高新区天晖中街56号1栋10楼1023号
        邮编：610041
      </p>
      <p>
        Q5：提交资料后，后续处理流程怎样呢？
      </p>

      <p>
        您提交相关申请资料后，我司客服人员将按照监护申请流程尽快处理您的申请，处理过程中可能要对监护人和被监护人进行电话回访以便信息的判定，希望您的配合。
      </p>
      <p>
        Q6：判定帐号归属需要多久呢？
      </p>

      <p>
        在判断申请材料完整的情况下，我司将联系疑似账号归属者，告知其在 15 个自然日内将按照监护人需求对其账号进行相关操作，并要求疑似账号归属者提供身份材料以便我司判定其与监护人监护关系；
        若疑似账号归属者在 15 个自然日内不能提供有效身份证明或逾期提供，则默认为疑似账号归属者与被监护人身份相符。我司即按照监护人申请要求，对其游戏账号纳入防沉迷系统；
        若疑似账号归属者在 15 个自然日内提供的身份证明与被监护人相符，我司即按照监护人申请要求，对其游戏账号纳入防沉迷系统；
        若疑似账号归属者在 15 个自然日内提供的身份证明与被监护人不符，我司则无法判定其与被监护人的身份关系。在此情况下，为保障用户游戏账号安全，我司将通知监护人通过公安机关出具账号找回协查证明，由我司协助被监护人找回游戏账号后再进行后续操作
      </p>
      <p>
        Q7:若发现对帐号归属有疑义或账号被恶意防沉迷限制怎么办？
      </p>

      <p>
        您需要在收到通知后15个自然日内提供您的身份证复印件（纸质），并通过以下地址邮寄到我司，如果我司在15个自然日内未收到您的身份证复印件，我们会将您的账号纳入家长监控系统。
        收件人：成都惊叹号科技有限公司 客服部
        地址：成都高新区天晖中街56号1栋10楼1023号
        邮编：610041
        注1：请在身份证复印件上写明您的签名以及QQ账号。
        注2：请您注意我司提供该项服务不收取任何费用，以免上当受骗。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  border-bottom: 4px solid #409eff;
  width: 160px;
  padding: 10px;
  font-size: 26px;
  font-weight: bold;
  margin: 10px;
}
.swiper {
  line-height: 40px;
  width: 800px;
  margin: 0 auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
