<template>
  <div id="content">
    <div class="swiper">
      <el-carousel height="600px">
        <el-carousel-item>
          <img src="../assets/imgs/1.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/2.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/3.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/4.jpg" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="swiper">
      <div class="title">
        游戏介绍
      </div>
      <el-card class="card">
        <router-link to="/game">
          <div>
            <img src="../assets/imgs/game.jpg" style="height:150px" alt="">
          </div>
          <div style="font-size:14px;line-height:16px;color:#409EFF">
            《卡车人生（TRUCK LIFE）》是一款国产独立休闲模拟游戏。数千公里真实路段，打卡近百处地标。数十座真实城市及网红小镇，中国的秋名山等你来战。感受原汁原味的中国地图，完成数百种城市特产任务。穿越烟火的城市相遇在山河湖海之间，快来分享你的卡车人生。
          </div>
        </router-link>
      </el-card>
    </div>

    <div class="info">

      <div style="width:1000px;margin:0 auto;padding:20px">
        <div class="title">
          新闻资讯
        </div>

        <div>
          <div class="red">
            最新资讯
          </div>
          <el-card shadow="hover">
            成都惊叹号科技有限公司成立于2018年10月26号，是一家专注于独立精品游戏开发的游戏研发公司。
          </el-card>
          <div class="bule">
            公司详情
          </div>
          <el-card shadow="hover">
            公司现位于成都市高新区，国内首款模拟驾驶游戏《卡车人生》已在steam平台上线，同时《卡车人生》同名手游也在研发制作当中。公司跟现代商用汽车、联合卡车以及莱仕达品牌都有着密切合作，公司软件开发技术和研发实力前景可观，致力于做出优秀的独立精品游戏，实现企业快速、稳定、健康的发展。
          </el-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.red {
  line-height: 18px;
  width: 100px;
  color: #fff;
  text-align: center;
  margin: 10px;
  background-color: red;
}
.bule {
  line-height: 18px;
  width: 100px;
  color: #fff;
  text-align: center;
  margin: 10px;
  background-color: #409eff;
}
.title {
  border-bottom: 4px solid #409eff;
  width: 160px;
  padding: 10px;
  font-size: 26px;
  font-weight: bold;
  margin: 10px;
}
#content {
  padding: 20px 0;
  background-color: #eee;
}
.card {
  cursor: pointer;
  width: 300px;
  height: 300px;
}
.info {
  /* background: url('../assets/imgs/banner.jpg')no-repeat ;
  background-size:100%; */
  /* width: 1000px; */
  /* margin: 0px auto; */
  background-color: #fff;
}
.swiper {
  width: 1000px;
  margin: 0px auto;
  margin-bottom: 20px;
}
.swiper img {
  width: 100%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
