<template>
  <div style="height:800px">
    <div style="cursor: pointer;width:300px;height:380px;background:#000;color:#fff;position:relative;margin:20px;">
      <router-link to="/gameinfo">
        <img src="../assets/imgs/game.jpg" style="width:100%;height:200px" class="image">
        <div style="font-size:20px;margin:10px">
          卡车人生
        </div>
        <div style="margin:10px">
          游戏类型：模拟游戏
        </div>
        <!-- <div style="margin:10px">
          游戏平台：Android
        </div> -->
        <div class="btn">
          查看游戏
        </div>
        <div class="gwbtn">
          官方网站
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'game',
  components: {
  },
  data () {

    return {
      activeIndex: '1',
    };
  },

  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped>
a {
  color: #fff;
}
.gwbtn {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  background: #e6a23c;
  line-height: 30px;
  text-align: center;
}
.btn {
  width: 80%;
  margin: 0 auto;
  background: #409eff;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
}
</style>