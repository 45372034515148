<template>
  <el-row>
    <el-col :span="18"  style="height:60px">
      <el-menu :default-active="activeIndex" class="el-menu-demo"  :router="true" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/home">
          首页
        </el-menu-item>
        <el-menu-item index="/game">
          游戏
        </el-menu-item>
        <el-menu-item index="/">帮助</el-menu-item>
        <el-menu-item index="/about">
          关于我们
        </el-menu-item>

      </el-menu>
    </el-col>
    <el-col :span="6"  style="border-bottom: solid 1px #e6e6e6;height:61px">
      <div class="info">
        <div>
          <img  src="../assets/imgs/juveniles.png" alt="">
          <router-link to='/custody'>
            本网站适合十八岁以上用户访问
          </router-link>
        </div>
        <div style="margin-top:4px">
          <img src="../assets/imgs/custody.png" alt="">
          <router-link to='/custody'>
            未成年人家长监护
          </router-link>
        </div>
      </div>
    </el-col>
  </el-row>

</template>

<script>
export default {
  data () {
    return {
      activeIndex: '/home',
    };
  },
  // created(){
  //   this.activeIndex = this.$route.path
  // },
  methods: {
    handleSelect (key, keyPath) {
      this.activeIndex = key
      if (key == '/') {
        this.activeIndex = '/home'
        alert('暂未开放！')
      }
      console.log(this.activeIndex);
      // console.log(key, keyPath);
    }
  },
  watch:{
  $route(to,from){
    if(to.path!=='/')
     this.activeIndex = to.path;
  }
},
}
</script>

<style scoped>

.info {
  
  height: 100%;
  line-height: 100%;
  margin-top: 10px;
}
.info a {
  color: #444;
}
.info a:hover {
  color: red;
}
.info img{
 width: 20px;
 vertical-align: middle;
}
.active a {
  color: #fff;
}
.content {
  position: relative;
}
.content a {
  display: inline-block;
  cursor: pointer;
  text-align: center;
}
.content a:hover {
  color: #fff;
}
#headerBanner {
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #444;
  height: 40px;
  line-height: 40px;
  z-index: 999;
}
#nav {
  z-index: 999;
  position: fixed;
  top: 40px;
  width: 100%;
  /* min-width: 1180px; */
  background-color: rgba(0, 0, 0, 0.8);
  line-height: 44px;
  height: 44px;
  color: white;
}
#navContent {
  margin: 0 auto;
  padding: 0 22px;
  max-width: 980px;
  font-size: 1em;
}
.productMenu {
  text-align: center;
  width: 190px;
  height: 176px;
  border: 1px solid rgba(252, 252, 252, 0.562);
  border-radius: 15px;
  position: absolute;
  left: -62px;
  top: 44px;
  background-color: rgba(252, 252, 252, 0.575);
  visibility: hidden;
  opacity: 0;
}
.productMenu a {
  color: rgb(27, 27, 27);
}
.showProduct {
  display: inline-block;
  color: #7e8c8d;
  cursor: pointer;
}
.showProduct:hover {
  color: #fff;
}
.showProduct:hover + .productMenu {
  visibility: visible;
  opacity: 1;
}
.productMenu:hover {
  visibility: visible;
  opacity: 1;
}
</style>