<template>
  <div>
    <el-row type="flex" style="width:1200px;margin:20px auto;background:#eee;padding:20px">
      <el-col>
        <div class="url">
          <p style="font-size:30px;font-weight:bold">成都惊叹号科技有限公司</p>
          <p style="line-height:18px">
            &emsp;&emsp;成都惊叹号科技有限公司成立于2018年10月26号，是一家专注于独立精品游戏开发的游戏研发公司。
            公司现位于成都市高新区，国内首款模拟驾驶游戏《卡车人生》已在steam平台上线，同时《卡车人生》同名手游也在研发制作当中。公司跟现代商用汽车、联合卡车以及莱仕达品牌都有着密切合作，公司软件开发技术和研发实力前景可观，致力于做出优秀的独立精品游戏，实现企业快速、稳定、健康的发展。
          </p>
          <p style="font-size:20px;font-weight:bold">地址:成都高新区天晖中街56号1栋10楼1023号</p>
          <p style="font-size:20px;font-weight:bold">电话：18108218004</p>
        </div>
      </el-col>

      <el-col>
        <div id="container">

        </div>
      </el-col>
    </el-row>

  </div>
</template>
<script type="text/javascript" src="http://webapi.amap.com/maps?v=1.4.15&key=2091d0358dd7929394eddd2715655efe"></script> 
<script>
export default {
  name: 'about',
  props: {
    msg: String
  },
  data () {
    return {
      key: '2091d0358dd7929394eddd2715655efe',
    }
  },
  mounted () {
    // 页面挂载完毕，开始异步引入高德地图
    // 创建了一个回调函数，高德地图加载完毕后会调用
    window.onLoad = function () {
      //图片加载完毕的回调函数
      //所有关于地图的逻辑全部都在这个回调里面，
      // 保证高德地图加载完毕
      var map = new AMap.Map("container", {
        zoom: 15,//级别
        center: [104.064818, 30.593763],//中心点坐标
        viewMode: '3D'//使用3D视图
      });
      // 创建一个 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(104.064818, 30.593763),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '北京'
      });

      // 将创建的点标记添加到已有的地图实例：
      map.add(marker);
    }
    var url = `https://webapi.amap.com/maps?v=1.4.15&key=${this.key}&callback=onLoad`;
    //  创建一个script dom元素
    var jsapi = document.createElement("script");
    //  设定script标签属性
    jsapi.charset = "utf-8";
    jsapi.src = url;
    // 将api文件引入到页面中，加载完毕以后就会回调函数 onLoad
    document.head.appendChild(jsapi)

  }
}
</script>

<style>
.url {
  margin: 20px;
  line-height: 60px;
}
#container {
  width: 800px;
  height: 500px;
}
</style>