<template>
  <div>
  
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {

    return {
      activeIndex: '1',
    };
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style>
</style>