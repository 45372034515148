<template>
  <div id="footer">
     <p style="text-align:center;padding:20px;font-size:20px">健康游戏公告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防上当受骗。适度游戏益脑，成谜游戏伤身。合理安排时间，享受健康生活</p>
    <div class="textBox">
      <el-row type='flex' justify="space-around">
        <el-col>
          <p>成都惊叹号科技有限公司</p>
          <p>地址:成都高新区天晖中街56号1栋10楼1023号</p>
          <p>电话：18108218004</p>
        </el-col>

      </el-row>

    </div>
    <div class="info">
     
      <p>
        <a href="http://beian.miit.gov.cn/" >备案号：蜀ICP备18036961号</a>
      </p>
      <p>Copyright&nbsp;&#169;&#65039;&nbsp;成都惊叹号科技有限公司&nbsp;版权所有 </p>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#footer {
  background-color: #eee;
  width: 100%;
  height:300px;
  /* color: #fff; */
}
.textBox {
  /* margin: 20px 0!important; */
  padding: 10px 20px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid#DCDFE6;
}
.textBox p {
  line-height: 30px;
}
.info {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
}
.info p {
  line-height: 22px;
}
</style>