<template>
  <div id="content">
    <div class="swiper">
      <el-carousel height="150px">
        <el-carousel-item>
          <img src="../assets/imgs/1.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/2.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/3.jpg" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/imgs/4.jpg" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
