<template>
  <div style="width:800px;margin:0 auto;line-height:40px">
    <div class="title">
      游戏介绍
    </div>
    <span style="font-size:20px;font-weight:bold">《卡车人生（TRUCK LIFE）》</span>是一款国产独立休闲模拟游戏。数千公里真实路段，打卡近百处地标。数十座真实城市及网红小镇，中国的秋名山等你来战。感受原汁原味的中国地图，完成数百种城市特产任务。穿越烟火的城市相遇在山河湖海之间，快来分享你的卡车人生。
    <div>
      <img src="../assets/imgs/gameinfo.jpg" style="width:400px;margin:20px" alt="">
    </div>
    <div class="info"> 卡车人生游戏背景</div>
    全新打造的卡车模拟游戏，体验多样的卡车驾驶乐趣。感受原汁原味的中国地图，完成数百种城市特产任务。穿越烟火的城市相遇在山河湖海之间，快来分享你的卡车人生。

    <div class="info">卡车人生游戏特色</div>
    <p>从轻卡到重卡，真实的驾驶体验</p>

    <p>收集改装配件，打造个性化车辆</p>

    <p>动态天气变化，湿滑路面请谨慎驾驶</p>

    <p>四季不同的特产热销，你知道大闸蟹几月份上市吗？</p>

    <p>数千公里真实路段，打卡近百处地标</p>

    <p>数十座真实城市及网红小镇，中国的秋名山等你来战</p>

    <a class="btn" href="http://pc.kuai8.com/game/kcrszwb/">
      点击下载
    </a>

  </div>
</template>

<script>
export default {
  components: {
  },
  data () {

    return {
      activeIndex: '1',
    };
  },

  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>

<style scoped>
.title {
  border-bottom: 4px solid #409eff;
  width: 160px;
  padding: 10px;
  font-size: 26px;
  font-weight: bold;
  margin: 10px;
}
.info {
  margin: 30px 0 15px 0;
  padding: 0 0 0 27px;
  border-left: 5px solid #b2bed3;
  background: #eee;
  color: #6e7f9d;
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
}
.gwbtn {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  background: #e6a23c;
  line-height: 30px;
  text-align: center;
}
.btn {
  display: block;
  cursor: pointer;
  width: 70%;
  margin: 20px auto;
  background: #409eff;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  font-size: 20px;
  color: #fff;
}
</style>