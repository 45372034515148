// 引入组件
import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
// import home from "./home.vue";
import about from '../pages/about.vue'
import home from '../pages/home.vue'
import game from '../pages/game.vue'
import gameinfo from '../pages/gameinfo.vue'
import contact from '../pages/contact.vue'
import juveniles from '../pages/juveniles.vue'
import custody from '../pages/custody.vue'

// 要告诉 vue 使用 vueRouter

const routes = [
  {
    path: '/',
    component: home,
  },
  {
    path: '/home',
    component: home,
  },
  {
    path: '/game',
    component: game,
  },
  {
    path: '/contact',
    component: contact,
  },
  {
    path: '/about',
    component: about,
  },
  {
    path: '/juveniles',
    component: juveniles,
  },
  {
    path: '/custody',
    component: custody,
  },
  {
    path: '/gameinfo',
    component: gameinfo,
  }
  
]

var router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})
export default router
